import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`👋 Welcome!`}</h1>
    <p>{`This is the `}<a parentName="p" {...{
        "href": "/knowledge-base",
        "title": "knowledge base"
      }}>{`knowledge base`}</a>{` for the open source technology `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{`.`}</p>
    <h2>{`Getting Started`}</h2>
    <ul>
      <li parentName="ul">{`If you want to get a `}<strong parentName="li">{`general sense of the platform`}</strong>{` and its capabilities, start with the page on `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` itself.`}</li>
      <li parentName="ul">{`If you are convinced of its utility and ready to implement it, start here:`}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Usage",
              "title": "🔩 Usage"
            }}>{`🔩 Usage`}</a>{``}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Quickstart",
              "title": "🏎 Quickstart"
            }}>{`🏎 Quickstart`}</a>{``}</li>
        </ul>
      </li>
    </ul>
    <p>{`Or, if you'd like help getting started, `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`📝 contact us`}</a>{`.`}</p>
    <h2>{`Integration & Analysis`}</h2>
    <ul>
      <li parentName="ul">{`If you are a `}<strong parentName="li">{`web developer`}</strong>{` and have been sent here to implement `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` on your organization's website, start with`}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Embedding-Polis-on-Your-Web-Property",
              "title": "Embedding Polis on Your Web Property"
            }}>{`Embedding Polis on Your Web Property`}</a>{``}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Identity",
              "title": "Identity"
            }}>{`Identity`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">{`If you are a `}<strong parentName="li">{`data scientist or data analyst`}</strong>{` and have been asked to evaluate `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` for your organization, check out:`}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/data-science-methods",
              "title": "data science methods"
            }}>{`data science methods`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">{`If you are an `}<strong parentName="li">{`organization`}</strong>{` interested in implementing `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{`, and need help with integration and analysis, please `}<a parentName="li" {...{
          "href": "/contact",
          "title": "contact"
        }}>{`contact`}</a>{` us.`}</li>
    </ul>
    <h2>{`Community`}</h2>
    <p>{`If you are an `}<strong parentName="p">{`interested citizen`}</strong>{`, read about `}<a parentName="p" {...{
        "href": "/Bringing-Polis-to-My-Political-Context",
        "title": "Bringing Polis to My Political Context"
      }}>{`Bringing Polis to My Political Context`}</a>{`.`}</p>
    <p>{`If you are curious about the non-profit organization which supports and maintains `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` and the main deployment `}<a parentName="p" {...{
        "href": "/pol.is",
        "title": "pol.is"
      }}>{`pol.is`}</a>{`, or getting involved to improve this ecosystem of tools for democracy, see the `}<a parentName="p" {...{
        "href": "/about"
      }}>{`about page`}</a>{` for description of our mission and vision, and our `}<a parentName="p" {...{
        "href": "/volunteering",
        "title": "volunteering"
      }}>{`volunteering`}</a>{` page if you'd like to help out.`}</p>
    <p>{`If you are interested in contributing to the codebase as an OSS developer, `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/polis"
      }}>{`check out the Github repo`}</a>{`, in particular the `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/polis/issues"
      }}>{`issues`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/polis/discussions"
      }}>{`discussions`}</a>{` pages.`}</p>
    <h2>{`About`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "/knowledge-base",
        "title": "knowledge base"
      }}>{`knowledge base`}</a>{` is maintained by the `}<a parentName="p" {...{
        "href": "about"
      }}>{`The Computational Democracy Project`}</a>{`, which maintains and supports `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/media-coverage",
          "title": "media coverage"
        }}>{`media coverage`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Case-studies",
          "title": "⚗️ Case studies"
        }}>{`⚗️ Case studies`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      